.work {
  padding: 0 1rem;

  .h2 {
    background: #376e8c;
    padding: 0.125rem 0.5rem;
    color: white;
    margin: 0 0 2rem;
    border-radius: 2px;
  }
}
