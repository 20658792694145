.header {
  position: relative;
  padding: 1rem;
  background: #376e8c;
  border-radius: 0 0 2px 2px;

  .h1 {
    position: relative;
    z-index: 1;
    padding: 0 0.25rem;
    background: #202020;
    margin: 1rem 1.5rem 1rem 0;
    color: white;
    border-radius: 2px;
  }

  .portrait {
    margin: 0;

    img {
      position: absolute;
      top: 1rem;
      right: -2rem;
      max-width: 35%;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: -1px 3px 5px 1px rgba(0, 0, 0, 0.2);
      z-index: 100;

      @media (min-width: 550px) {
        max-width: 25%;
        right: -1.25rem;
      }
    }
  }

  .blurb {
    position: relative;
    max-width: 65%;
    padding: 0.25rem;
    margin: 1rem 0 0.5rem;
    background: white;
    color: #202020;
    border-radius: 2px;
  }
}
